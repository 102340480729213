import './App.css';
import { useEffect, useState, useRef } from 'react'
import Form from "./interviewForm";
import Simulator from "./Simulator";
import Modal from 'react-modal';
import Flag from 'react-world-flags';
import ChooseForm from "./testiform2";
import Logopikku from "./logo.png";
import AI from "./AI2.png";
import Nav from "./navbar4";
import Simulator2 from "./Simulator2";

function Hallinta() {

  const [language, setLanguage] = useState("en-US");
  const [isOpen, setIsOpen] = useState(true);
  const [login, setLogin] = useState(false);
  const [authentication, setAuthentication] = useState(false);
  const [form, setForms] = useState(false);
  const [flag, setFlags] = useState(false);
  const [kredit, setKredit] = useState(-1);
  const [finland, setFinland] = useState(false);

  const [haastattelu, setHaastattelu] = useState(false);

  //valinnat
  const [selectedOptions, setSelectedOptions] = useState({});
 
  function loadKredits(kredit) {
    setKredit(kredit)
  }

  const addLogin = (value) => {
    setLogin(true);
};
  const addHaastattelu = (value) => {
    setHaastattelu(true);
  };


  const handleCloseModal = () => {
    setIsOpen(false);
  }

  function changeLanguage(lan) {
    setLanguage(lan)
    setFlags(false);
    setForms(true);
  }

  function closeOpen( freeText, selectedOption, searchText,freeText2 ) {
    setForms(false);
    setIsOpen(false);

    setSelectedOptions(prevState => ({
      ...prevState,
      level: selectedOption,
      industry: searchText,
      position: freeText,
      url: freeText2,   
    }));
  }

  function changeAuthentication() {
    setAuthentication(true);
    setFlags(true);
  }
  

  useEffect(() => {

    const storedEmail = sessionStorage.getItem('email');
    const withGoogle = sessionStorage.getItem('withGoogle');
    if (storedEmail ) {
      setAuthentication(true);
      setFlags(true);
    } 
    const domain = window.location.hostname;

    if (domain === 'theinterviewsimulator.com' || domain === 'www.theinterviewsimulator.com') {
      setFinland(false);
      
    } else {
      setFinland(true);
    }
    
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    

    // Komponentin poistuessa, poista tapahtumankäsittelijä
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const myRef = useRef();

      useEffect(() => {
        myRef.current.focus();
  }, [flag]);

  
  return (
    <div className="Front" ref={myRef}>     
      {!login && <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={handleCloseModal}
        contentLabel="Esimerkki modaali"
        shouldCloseOnOverlayClick={false}
        className={form ? "ChoiceModal" : "AppModal"}
        overlayClassName="AppModalOverlay">
        
        {(form && !login) && <ChooseForm function={closeOpen} lan={language}/>}
        {!authentication && <Form onAddLogin={addLogin} onAddHaastattelu={addHaastattelu} />} 
        
      </Modal>}
      {isOpen && <div><Nav lan={finland} haa={haastattelu}/></div>}
      {(isOpen && !login) && <div><img src={AI} className='AI' alt="The Interview Simulator"/></div>}
      {(isOpen && !login) && <div><img src={Logopikku} className='LomakeLogo' alt="Lassi Albin"></img></div>}
      {(isOpen && !login) && <div><h1 className='LomakeTitle'>The Interview Simulator</h1></div>} 
    
      {(isOpen && !login) && <div><h1 className='Footer'>© 2024 LASSI ALBIN. ALL RIGHTS RESERVED.</h1></div>}   
   
        
      
    </div>
  );}
  
export default Hallinta;