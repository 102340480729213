
import { AiOutlineHome } from "react-icons/ai";
import Logopikku from "./logo.png";
import Flag from 'react-world-flags';
import { VscFeedback, VscSignOut } from "react-icons/vsc";

const MyNavbar3 = (props) => {
 
  const openLink = () => {
    if (props.lan){
      window.open('https://www.lassialbin.com/tyohaastattelusimulaattori', '_blank');
    }else{
      window.open('https://www.lassialbin.com/simulatorfree', '_blank');
    }
    
  };
  const openLink2 = () => {
    window.open('https://www.lassialbin.com/', '_blank');
  };
  return (
    <div className='FormNav' style={{display: "flex", justifyContent: "space-between"}}>
      
    <span onClick={() => openLink2()} className="FormHome2" style={{fontSize:"2.8vh", color:"#aaaaaa", marginLeft:"2vh", cursor:"pointer"}}>
        <img src={Logopikku} height={40} alt="Lassi Albin"></img>
    </span>
          
     <div>
     
     <span 
        onClick={(e) => {
          window.location.reload();  // Sivun lataaminen uudelleen
        }} 
        className="FormHome" 
        style={{fontSize: "2.8vh", color:"#aaaaaa", marginRight:"2vh", cursor:"pointer"}}
      >
        {props.haa !== true && <VscSignOut style={{ fontSize: "1.5em" }} />}
      </span>
    </div>
</div>
  );
  };
  
export default MyNavbar3;