import './App.css';
import { useEffect, useRef, useState } from 'react';
import Logopikku from "./logopikku.png";

function IndInterview(props) {

     
  const [motQuestions, setMotQuestions] =useState([]);
  const [title, setTitle] = useState("");
  const loading = useRef(false);
  const [loadInfo, setLoadInfo]=useState("");
    /*if (props.lan === "fi"){
      //setFitQuestions(fitQuestionsFin);
      setFitQuestions(fitQuestionsFin);
      
    }
    else if (props.lan === "en-US"){
      setFitQuestions(fitQuestionsUS);
      
    }
    else if (props.lan === "es-ES"){
      setFitQuestions(fitQuestionsEsp);
      
    }*/



const loadQuestions = async (options) => {
  loading.current = true;
  try {
    const response = await fetch('https://us-central1-simudatauser.cloudfunctions.net/api/load', {
      //const response = await fetch('http://127.0.0.1:5001/simudatauser/us-central1/api/load', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ options: options, lan: props.lan })
      });

     // Tarkistetaan, että pyyntö onnistui
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Parsitaan suoraan JSON-muotoon, koska palautus on validi JSON
    const questionArray = await response.json();

    // Tarkistetaan, että data on taulukko
    if (!Array.isArray(questionArray)) {
      throw new Error('Data is not an array!');
    }

    // Tarkistetaan, että taulukko ei ole tyhjä
    if (questionArray.length === 0) {
      setMotQuestions(["No questions found."]);
    } else {
      setMotQuestions(questionArray);  // Asetetaan taulukko setMotQuestions-funktioon
    }
  } catch (error) {
    console.error('Error fetching data:', error.message);
    setMotQuestions(["Oops! There was an error loading questions."]);
  } finally {
    loading.current = false;
  }
};




      
    useEffect(() => {
      //Mejores preguntas de entrevista de aptitud
      if (props.lan === "fi"){
        setTitle("Parhaat asiantuntemuskysymykset");  
        setLoadInfo("Generoidaan kysymyksiä...");
      }
      else if (props.lan === "en-US"){
        setTitle("Top Professional\n Questions");
        setLoadInfo("Generating questions...");
        
      }
      else if (props.lan === "es-ES"){
        setTitle("Las mejores preguntas\n profesionales");
        setLoadInfo("enerando preguntas...");
        
      }
      if (motQuestions.length === 0 && loading.current == false) {
   
      loadQuestions(props.options);
      }
    }, [props.options]);
    
    if (motQuestions.length === 0) {
      return (
        <div className="Ind">
          <div style={{maxWidth:"80%", textAlign:"center", marginLeft:"10%", fontSize:"0.9em"}}>
         <h1 className='QuestionTitle'>{title}</h1></div>
         <img className="logopikku" src={Logopikku} alt="kuva" />
         <p  style={{fontSize:"1.3em"}}>{loadInfo}</p>
        </div>
      );
    }else {
      return (
        <div className="Ind">
         <div style={{maxWidth:"80%", textAlign:"center", marginLeft:"10%", fontSize:"0.9em"}}>
         <h1 style={{whiteSpace: 'pre-wrap'}} className='QuestionTitle'>{title}</h1></div>
         
          {motQuestions.map((question, index) =>
            <div key={index} className='Questions'>
               <p onClick={() => props.function(motQuestions[index])}  className='Question' key={index}>{question}</p>
            </div>
          )}
        </div>
      );
    }
  
}

export default IndInterview;