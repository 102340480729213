import { useEffect, useState } from 'react';
import './Mandaatti.css';
import CryptoJS from 'crypto-js';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Modal from 'react-modal';
import Logopikku from "./logopikku.png";


function Mandaatti() {

  //const encryptionKey = 'kimi1234'; // Esimerkiksi voit käyttää ympäristömuuttujia
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

  const [formErrors, setFormErrors] = useState({});

    // FormData ja formGPT ennen salausta
   


  const [konsultit, setKonsultit] = useState([]);
  const [kielet, setKielet] = useState(["Suomi", "Ruotsi", "Englanti"]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [ready, setReady] = useState(false);
  const [activeTab, setActiveTab] = useState('form1'); // Vaihdetaan lomakkeiden välillä

  const [nimi, setNimi] = useState('');
  const [group, setGroup] = useState('');
  const [latausKaynnissa, setLatausKaynnissa] = useState(false);
  

    // Avaa vahvistusmodaali
    const openModal = () => {
      const errors = {};
  
    if (!company) errors.company = "Yritys on pakollinen kenttä";
    if (!contactPerson) errors.contactPerson = "Yhteyshenkilö on pakollinen kenttä";
    if (!valittuKonsultti) errors.valittuKonsultti = "Konsultti on pakollinen valinta";
    if (!valittuKieli) errors.valittuKieli = "Haastattelukieli on pakollinen valinta";
    if (!jobTitle) errors.jobTitle = "Haettava tehtävä on pakollinen kenttä";
    if (!industry) errors.industry = "Toimiala on pakollinen kenttä";
  
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsModalOpen(true);
    }else{
      setIsModalOpen(true);
    }
    };
  
    // Sulje vahvistusmodaali
    const closeModal = () => {
      setIsModalOpen(false);
    };

      // Funktio aktiivisen välilehden vaihtamiseen
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  
  //Yritys
  const [company, setCompany] = useState("");
  //Konsultti
  const [valittuKonsultti, setValittuKonsultti] = useState('');
  //Kieli
  const [valittuKieli, setValittuKieli] = useState('');
  // YRITYKSEN YHTEYSHENKILÖ
  const [contactPerson, setContactPerson] = useState("");

  // HAETTAVA TEHTÄVÄ/TITTELI
  const [jobTitle, setJobTitle] = useState("");

  // Toimiala
  const [industry, setIndustry] = useState("");

  // 1. LIIKETOIMINTATARVE
  const [businessReason, setBusinessReason] = useState("");
  const [companyStrategy, setCompanyStrategy] = useState("");
  const [oneYear, setOneYear] = useState("");

  // 2. TEHTÄVÄ
  const [mainTasks, setMainTasks] = useState("");
  const [reportTo, setReportTo] = useState("");
  const [travelDays, setTravelDays] = useState("");

  // 3. VASTUUT
  const [responsibilities, setResponsibilities] = useState("");

  // 4. TAVOITTEET JA MITTARIT
  const [goals100Days, setGoals100Days] = useState("");
  const [goals1Year, setGoals1Year] = useState("");
  const [goals2To3Years, setGoals2To3Years] = useState("");
  const [performanceMetrics, setPerformanceMetrics] = useState("");
  const [criticalSituations, setCriticalSituations] = useState("");

  // 5. KOKEMUS JA NÄYTÖT
  const [experience, setExperience] = useState("");
  const [achievements, setAchievements] = useState("");
  const [background, setBackground] = useState("");

  // 6. OSAAMINEN
  const [skillsRequired, setSkillsRequired] = useState("");

  // 7. PERSOONA JA TYÖSKENTELYTAPA
  const [personalityTraits, setPersonalityTraits] = useState("");
  const [workLevel, setWorkLevel] = useState("");
  const [workFocus, setWorkFocus] = useState("");

  // 8. TOIMINTAKULTTUURI JA YRITYKSEN ARVOT
  const [teamDescription, setTeamDescription] = useState("");
  const [cultureTraits, setCultureTraits] = useState("");
  const [leadershipStyle, setLeadershipStyle] = useState("");
  const [companyValues, setCompanyValues] = useState("");

  // 9. HOUKUTTELUTEKIJÄT
  const [organizationAppeal, setOrganizationAppeal] = useState("");
  const [jobAppeal, setJobAppeal] = useState("");
  const [salaryAndIncentives, setSalaryAndIncentives] = useState("");
  const [peopleAppeal, setPeopleAppeal] = useState("");
  const [growthOpportunities, setGrowthOpportunities] = useState("");

  // 10. YRITYKSEN SISÄISET HAKIJAT
  const [internalCandidates, setInternalCandidates] = useState("");

  // 11. ULKOPUOLISET HAKIJAT
  const [externalCandidates, setExternalCandidates] = useState("");

  // 12. YRITYKSEN KILPAILIJAT
  const [competitors, setCompetitors] = useState("");

  // 13. KIELLETYT TAHOT, JOIHIN EI SAA OLLA YHTEYDESSÄ
  const [prohibitedContacts, setProhibitedContacts] = useState("");

  // 14. FINAALIHAASTATTELUIHIN OSALLISTUVAT HENKILÖT
  const [finalInterviewParticipants, setFinalInterviewParticipants] = useState("");

  // 15. ALUSTAVA FINAALIHAASTATTELUPÄIVÄ
  const [finalInterviewDate, setFinalInterviewDate] = useState("");

  // Top 5 kriteerit
  const [top5Criteria, setTop5Criteria] = useState("");

  // Avoin kuvaus
  const [avoin, setAvoin] = useState("");

  const [luottamus, setLuottamus] = useState("LUOTTAMUKSELLINEN");
  const [yritys, setYritys] = useState("YRITYS:");
  const [yhteyshenkilo, setYhteyshenkilo] = useState("YRITYKSEN YHTEYSHENKILÖ:");
  const [haettavaTehtava, setHaettavaTehtava] = useState("HAETTAVA TEHTÄVÄ/TITTELI:");
  const [toimiala, setToimiala] = useState("Toimiala");
  const [liiketoiminnallisetSyyt, setLiiketoiminnallisetSyyt] = useState("1.1 Mitkä ovat liiketoiminnalliset syyt rekrytoinnille?");
  const [yrityksenStrategia, setYrityksenStrategia] = useState("1.2 Yrityksen strategia - ja mikä rooli valittavalla henkilöllä on strategian toteutumisessa?");
  const [vuodenPaattaenParempi, setVuodenPaattaenParempi] = useState("1.3 Mikä yrityksessä on vuoden päästä paremmin, kun valittu henkilö on onnistunut työssään?");
  const [keskeisetTehtavat, setKeskeisetTehtavat] = useState("2.1 Mitkä ovat henkilön keskeiset tehtävät?");
  const [raportointi, setRaportointi] = useState("2.2 Kenelle henkilö raportoi?");
  const [matkapäivät, setMatkapäivät] = useState("2.3 Työhön liittyvien pakollisten matkapäivien arvioitu määrä ja aihe/v");
  const [keskeisetVastuualueet, setKeskeisetVastuualueet] = useState("3.1 Mitkä ovat henkilön keskeiset vastuualueet?");
  const [aikaiseksi, setAikaiseksi] =useState("4.1 Mitä valittavan henkilön on saatava konkreettisesti aikaiseksi:") 
  const [ensimmaiset100paivaa, setEnsimmaiset100paivaa] = useState("4.1.1 Ensimmäisten 100 päivän aikana");
  const [ensimmanenVuosi, setEnsimmanenVuosi] = useState("4.1.2 Ensimmäisen vuoden aikana");
  const [ensimmaiset2_3vuotta, setEnsimmaiset2_3vuotta] = useState("4.1.3 Ensimmäisten 2–3 vuoden aikana");
  const [tavoitteetJaMittarit, setTavoitteetJaMittarit] = useState("4.2 Mitkä ovat konkreettiset tavoitteet ja mittarit, jotka todentavat henkilön/tehtävän tuottavuuden?");
  const [avainTilanteet, setAvainTilanteet] = useState("4.3 Kuvaile niitä tehtävän kriittisiä avaintilanteita, jotka ratkaisevasti voivat vaikuttaa tehtävässä menestymiseen tai epäonnistumiseen");
  const [kokemus, setKokemus] = useState("5.1 Mitä kokemusta tehtävässä menestymisessä tarvitaan (5-6 vaihtoehtoa)?");
  const [onnistumiset, setOnnistumiset] = useState("5.2 Mistä asioista ja onnistumisista henkilöllä tulee olla näyttöjä?");
  const [tausta, setTausta] = useState("5.3 Millainen tausta on sopivin tähän tehtävään (2-3 vaihtoehtoa)?");
  const [osaaminen, setOsaaminen] = useState("6.1 Mitä osaamista tehtävässä menestymisessä tarvitaan (5-6 vaihtoehtoa)?");
  const [luonteenpiirteet, setLuonteenpiirteet] = useState("7.1 Mitä luonteenpiirteitä / ominaisuuksia tehtävässä menestymisessä tarvitaan? Valikoi 3–5 keskeisintä tekijää, jotka uuden henkilön persoonassa vaikuttavat tehtävässä menestymiseen.");
  const [tyoskentelynTasot, setTyoskentelynTasot] = useState("7.2 Työskentelyn tasot: strateginen (%), taktinen (%), operatiivinen (%).");
  const [tyoskentelynPainoarvo, setTyoskentelynPainoarvo] = useState("7.3 Työskentelyn painoarvo: ennakointi ja suunnitelmallisuus (%), hetkessä reagointi (%).");
  const [tyoryhma, setTyoryhma] = useState("8.1 Kuvaile millaiseen työryhmään/työyhteisöön uusi henkilö sijoittuu?");
  const [toimintakulttuuri, setToimintakulttuuri] = useState("8.2 Kuvaile mitkä ovat yrityksen toimintakulttuurissa näkyviä/hallitsevia piirteitä?");
  const [johtamiskaytannot, setJohtamiskaytannot] = useState("8.3 Kuvaile yrityksen johtamiskäytäntöjä ja tapoja toimia.");
  const [arvot, setArvot] = useState("8.4 Mitkä ovat yrityksen arvot?");
  const [motivaatiotekijat, setMotivaatiotekijat] = useState("Mitkä ovat ne onnistumisen, tuottavuuden ja jatkuvuuden kannalta tärkeimmät tekijät, joista henkilö tulee luultavimmin motivoitumaan työssään?");
  const [organisaatio, setOrganisaatio] = useState("9.1 Organisaatio (markkina-asema, tuotteiden ja/tai palveluiden laatu, asiakkaat, yritysvastuullisuus, maine)");
  const [tyo, setTyo] = useState("9.2 Työ (työn mielekkyys, työn sisältö, työn ja vapaa-ajan tasapaino, myös jousto)");
  const [kuukausiansiot, setKuukausiansiot] = useState("9.3 Kuukausiansiot + palkitseminen");
  const [ihmiset, setIhmiset] = useState("9.4 Ihmiset (kollegat, työntekijöiden maine sisä- ja ulkopuolella)");
  const [mahdollisuudet, setMahdollisuudet] = useState("9.5 Mahdollisuudet (urapolut, kehitysmahdollisuudet, organisaation kasvu/kehitystavoitteet)");
  const [sisaisetHakijat, setSisaisetHakijat] = useState("10. YRITYKSEN SISÄISET HAKIJAT");
  const [ulkopuolisetHakijat, setUlkopuolisetHakijat] = useState("11. ULKOPUOLISET HAKIJAT");
  const [kilpailijat, setKilpailijat] = useState("12. YRITYKSEN KILPAILIJAT");
  const [kielletytTahot, setKielletytTahot] = useState("13. KIELLETYT TAHOT, JOIHIN EI SAA OLLA YHTEYDESSÄ");
  const [finaalihaastattelijat, setFinaalihaastattelijat] = useState("14. FINAALIHAASTATTELUIHIN OSALLISTUVAT HENKILÖT");
  const [alustavaFinaalihaastatteluPäivä, setAlustavaFinaalihaastatteluPäivä] = useState("15. ALUSTAVA FINAALIHAASTATTELUPÄIVÄ");
  const [top5Kriteerit, setTop5Kriteerit] = useState("Top 5 kriteerit:");

  

  const handleInputChange = (e, setState) => {
    const text = e.target.value;
    setState(text);
  };
  
  // Päivitetty handleKeyDown ilman pallukoiden lisäystä tai poistoa
  const handleKeyDown = (e, setState) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      // Lisää uusi rivi ilman pallukkaa
      setState(prev => {
        const newValue = `${prev}\n`;
        setTimeout(() => {
          const textArea = e.target;
          textArea.style.height = "auto"; // Nollaa korkeus
          textArea.style.height = `${textArea.scrollHeight}px`; // Aseta korkeus sisällön mukaan
        }, 0);
        return newValue;
      });
    } else if (e.key === 'Backspace') {
      // Jos halutaan säilyttää normaali rivin poistotoiminto, ei tarvitse lisätoimintoja
    }
  };



  useEffect(() => {
    if (group === "") {
        const currentUrl = window.location.href;
        const parsedUrl = new URL(currentUrl);
        const pathParts = parsedUrl.pathname.split('/');
        if (pathParts.length > 1) {
            setGroup(pathParts[1]); // tallennetaan "prosource" tilaan
        }
    }
  
}, [group]);

  const confirmSubmit = async () => {

    


      let formData = {};
      let formGPT = {};
    

      setIsSending(true);
      
      if(activeTab =="form1"){



        formData ={
          company,                      // Yritys
          valittuKonsultti,             // Konsultti
          valittuKieli,                 // Kieli
          contactPerson,                // Yrityksen yhteyshenkilö
          
          jobTitle,                     // Haettava tehtävä/titteli
          industry,                     // Haettava tehtävä/titteli
          
          businessReason,               // Liiketoimintatarve
          companyStrategy,              // Yrityksen strategia
          oneYear,                      // Ensimmäisen vuoden tavoitteet
          
          mainTasks,                    // Tehtävä
          reportTo,                     // Raportointi
          travelDays,                   // Matkapäivät
          
          responsibilities,             // Vastuut
          
          goals100Days,                 // Tavoitteet 100 päivää
          goals1Year,                   // Tavoitteet 1 vuosi
          goals2To3Years,               // Tavoitteet 2-3 vuotta
          performanceMetrics,           // Suorituskykymittarit
          criticalSituations,           // Kritiittiset tilanteet
          
          experience,                   // Kokemus ja näytöt
          achievements,                 // Saavutukset
          background,                   // Tausta
          
          skillsRequired,               // Osaamistarpeet
          
          personalityTraits,            // Persoonallisuus ja työskentelytapa
          workLevel,                    // Työtaso
          workFocus,                    // Työskentelyn painopisteet
          
          teamDescription,              // Tiimin kuvaus
          cultureTraits,                // Toimintakulttuuri
          leadershipStyle,              // Johtamistyylit
          companyValues,                // Yrityksen arvot
          
          organizationAppeal,           // Houkuttelutekijät - Organisaation vetovoima
          jobAppeal,                    // Houkuttelutekijät - Tehtävän vetovoima
          salaryAndIncentives,          // Palkka ja kannustimet
          peopleAppeal,                 // Houkuttelutekijät - Ihmiset
          growthOpportunities,          // Kasvumahdollisuudet
          
          internalCandidates,           // Sisäiset hakijat
          externalCandidates,           // Ulkopuoliset hakijat
          
          competitors,                  // Kilpailijat
          
          prohibitedContacts,           // Kielletut tahot
          finalInterviewParticipants,   // Finaalihaastatteluihin osallistuvat
          finalInterviewDate,           // Alustava finaalihaastattelupäivä
          
          top5Criteria,                  // Top 5 kriteerit
        };

        formGPT ={
          "Yritys": company,
          "Konsultti": valittuKonsultti,
          "Kieli": valittuKieli,
          
          "Haettava tehtävä/titteli": jobTitle,
          "Toimiala": industry,
          
          "Liiketoimintatarve": businessReason,
          "Yrityksen strategia": companyStrategy,
          "Ensimmäisen vuoden tavoitteet": oneYear,
          
          "Tehtävä": mainTasks,
          "Matkapäivät": travelDays,
          
          "Vastuut": responsibilities,
          
          "Tavoitteet 100 päivää": goals100Days,
          "Tavoitteet 1 vuosi": goals1Year,
          "Tavoitteet 2-3 vuotta": goals2To3Years,
          "Suorituskykymittarit": performanceMetrics,
          "Kritiittiset tilanteet": criticalSituations,
          
          "Kokemus ja näytöt": experience,
          "Saavutukset": achievements,
          "Tausta": background,
          
          "Osaamistarpeet": skillsRequired,
          
          "Persoonallisuus ja työskentelytapa": personalityTraits,
          "Työtaso": workLevel,
          "Työskentelyn painopisteet": workFocus,         
          
          "Top 5 kriteerit": top5Criteria
        };
      }else{
        formData ={
          company,                      // Yritys
          valittuKonsultti,             // Konsultti
          valittuKieli,                 // Kieli
          contactPerson,                // Yrityksen yhteyshenkilö
          
          jobTitle,                     // Haettava tehtävä/titteli
          industry,                     // Haettava tehtävä/titteli
          
          avoin
        };

        formGPT={
          "Yritys": company,
          "Konsultti": valittuKonsultti,
          "Kieli": valittuKieli,
          
          "Haettava tehtävä/titteli": jobTitle,
          "Toimiala": industry,
          
          "Avoin työnkuvaus": avoin
        };

      }
    
    
      const encryptedFormData = CryptoJS.AES.encrypt(JSON.stringify(formData), encryptionKey).toString();
      const encryptedFormGPT = CryptoJS.AES.encrypt(JSON.stringify(formGPT), encryptionKey).toString();
      

      setLatausKaynnissa(true);

      fetch('https://us-central1-simudatauser.cloudfunctions.net/api/saveMandate', {
        //fetch('http://localhost:5001/simudatauser/us-central1/api/saveMandate', {

      
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
              data: encryptedFormData, 
              nimi: valittuKonsultti, 
              group: group, 
              gpt: encryptedFormGPT 
          })
        })
        .then(response => response.text())  // Parse the response as text instead of JSON
        .then(text => {
            setLatausKaynnissa(false); // Turn off the loading state
            if (text === "valmis") {
              setIsSending(false);
              setReady(true);
            } else {
                // Attempt to parse text as JSON if it's in JSON format
                try {
                    const data = JSON.parse(text);
                  
                } catch (error) {
                    console.warn("Unexpected response format:", text);
                }
            }
        })
        .catch(error => console.error('Fetch error:', error));
      
         
  }

  const haeKonsultit = async () => {
    setLatausKaynnissa(true);
    try {
      const response = await fetch('https://us-central1-simudatauser.cloudfunctions.net/api/loadConsults', {
        //const response = await fetch('http://localhost:5001/simudatauser/us-central1/api/loadConsults', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: nimi })
      });
      
      const data = await response.json();
      if (data && data.length > 0) {
        setKonsultit(data);
       
      } else {
        setKonsultit(["No consultants found"]);
      }
    } catch (error) {
      console.error(error);
      setKonsultit(["Error loading consultants"]);
    } finally {
      setLatausKaynnissa(false);
    }
  };

  useEffect(() => {
    if (nimi !== "") {
      haeKonsultit();
    }
  }, [nimi]);

  useEffect(() => {
    if (nimi === "") {
      const currentUrl = window.location.href;
      const parsedUrl = new URL(currentUrl);
      const pathParts = parsedUrl.pathname.split('/');
      if (pathParts.length > 1) {
        setNimi(pathParts[1]);
      }
    }
   
  }, [nimi]);

  return (
    <div className="mandaatti-container">
      {/* Välilehdet */}
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '3vh' }}>
        <button
          onClick={() => handleTabChange('form1')}
          style={{
            padding: '1vh 2vh',
            backgroundColor: activeTab === 'form1' ? '#007bff' : '#f0f0f0',
            color: activeTab === 'form1' ? '#fff' : '#000',
            border: 'none',
            cursor: 'pointer',
            marginRight: '1vh'
          }}
        >
          Mandaatti
        </button>
        <button
          onClick={() => handleTabChange('form2')}
          style={{
            padding: '1vh 2vh',
            backgroundColor: activeTab === 'form2' ? '#007bff' : '#f0f0f0',
            color: activeTab === 'form2' ? '#fff' : '#000',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          Avoin kuvaus
        </button>
      </div>
     <div className="form-group" style={{ marginTop: "0vh", marginBottom: "5vh" }}>
      <label style={{ textDecoration: "underline" }}>{luottamus}</label>
    </div>
    
    {activeTab === 'form1' && ( <div>
    <p style={{ position: 'absolute', top: '2vh', right: '5vh' }}>
    Lue tietosuojailmoitus <a href="https://www.haastattelusimulaattori.com/policy2.html" target="_blank" rel="noopener noreferrer">täältä</a> 
      </p>
      <h2>TARKENNETTU HAKUPROFIILI </h2>

      <div className="form-group">
        <label>{yritys}</label>
        <input
          type="text"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>{yhteyshenkilo}</label>
        <input
          type="text"
          value={contactPerson}
          onChange={(e) => setContactPerson(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>Valitse konsultti:</label>
        <select value={valittuKonsultti} onChange={(e) => setValittuKonsultti(e.target.value)}>
          <option value="">{latausKaynnissa ? "Loading..." : "Valitse konsultti"}</option>
          {!latausKaynnissa && konsultit.map((konsultti, index) => (
            <option key={index} value={konsultti}>{konsultti}</option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Valitse haastattelukieli:</label>
        <select value={valittuKieli} onChange={(e) => setValittuKieli(e.target.value)}>
          <option value="">Valitse haastattelukieli</option>
          { kielet.map((konsultti, index) => (
            <option key={index} value={konsultti}>{konsultti}</option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>{haettavaTehtava}</label>
        <input
          type="text"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>{toimiala}</label>
        <input
          type="text"
          value={industry}
          onChange={(e) => setIndustry(e.target.value)}
        />
      </div>
      <div className="form-group" style={{ marginTop: "5vh" }}>
        <label>1. LIIKETOIMINTATARVE</label>
      </div>

      <div className="form-group" style={{ marginTop: "5vh" }}>
        <label>{liiketoiminnallisetSyyt}</label>
      </div>

      <textarea
      value={businessReason}
      onChange={(e) => {
        handleInputChange(e, setBusinessReason);
        e.target.style.height = "auto";
        e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
      }}
      onKeyDown={(e) => handleKeyDown(e, setBusinessReason, businessReason)}
      className="form-control"
      style={{ 
        overflow: 'hidden', 
        height: '1vh', 
        marginBottom: "1vh"
      }} 
    />

<div className="form-group">
  <label>{yrityksenStrategia}</label>
  <textarea
    value={companyStrategy}
    onChange={(e) => {
      handleInputChange(e, setCompanyStrategy);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setCompanyStrategy, companyStrategy)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{vuodenPaattaenParempi}</label>
  <textarea
    value={oneYear}
    onChange={(e) => {
      handleInputChange(e, setOneYear);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setOneYear, oneYear)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

      <div className="form-group" style={{ marginTop: "5vh" }}>
  <label>2. TEHTÄVÄ</label>
</div>

<div className="form-group">
  <label>{keskeisetTehtavat}</label>
  <textarea
    value={mainTasks}
    onChange={(e) => {
      setMainTasks(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setMainTasks, mainTasks)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{raportointi}</label>
  <textarea
    value={reportTo}
    onChange={(e) => {
      setReportTo(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setReportTo, reportTo)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{matkapäivät}</label>
  <textarea
    value={travelDays}
    onChange={(e) => {
      setTravelDays(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setTravelDays, travelDays)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group" style={{ marginTop: "5vh" }}>
  <label>3. VASTUUT</label>
</div>

<div className="form-group">
  <label>{keskeisetVastuualueet}</label>
  <textarea
    value={responsibilities}
    onChange={(e) => {
      setResponsibilities(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setResponsibilities, responsibilities)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group" style={{ marginTop: "5vh" }}>
  <label>4. TAVOITTEET JA MITTARIT</label>
</div>

<div className="form-group">
  <label>{aikaiseksi}</label>
  <label>{ensimmaiset100paivaa}</label>
  <textarea
    value={goals100Days}
    onChange={(e) => {
      setGoals100Days(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setGoals100Days, goals100Days)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{ensimmanenVuosi}</label>
  <textarea
    value={goals1Year}
    onChange={(e) => {
      setGoals1Year(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setGoals1Year, goals1Year)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{ensimmaiset2_3vuotta}</label>
  <textarea
    value={goals2To3Years}
    onChange={(e) => {
      setGoals2To3Years(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setGoals2To3Years, goals2To3Years)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>


<div className="form-group">
  <label>{tavoitteetJaMittarit}</label>
  <textarea
    value={performanceMetrics}
    onChange={(e) => {
      setPerformanceMetrics(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setPerformanceMetrics, performanceMetrics)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{avainTilanteet}</label>
  <textarea
    value={criticalSituations}
    onChange={(e) => {
      setCriticalSituations(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setCriticalSituations, criticalSituations)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group" style={{ marginTop: "5vh" }}>
  <label>5. KOKEMUS JA NÄYTÖT</label>
</div>

<div className="form-group">
  <label>{kokemus}</label>
  <textarea
    value={experience}
    onChange={(e) => {
      setExperience(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setExperience, experience)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{onnistumiset}</label>
  <textarea
    value={achievements}
    onChange={(e) => {
      setAchievements(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setAchievements, achievements)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{tausta}</label>
  <textarea
    value={background}
    onChange={(e) => {
      setBackground(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setBackground, background)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group" style={{ marginTop: "5vh" }}>
  <label>6. Osaaminen</label>
</div>

<div className="form-group">
  <label>{osaaminen}</label>
  <textarea
    value={skillsRequired}
    onChange={(e) => {
      setSkillsRequired(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setSkillsRequired, skillsRequired)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group" style={{ marginTop: "5vh" }}>
  <label>7. PERSOONA JA TYÖSKENTELYTAPA</label>
</div>

<div className="form-group">
  <label>{luonteenpiirteet}</label>
  <textarea
    value={personalityTraits}
    onChange={(e) => {
      setPersonalityTraits(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setPersonalityTraits, personalityTraits)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{tyoskentelynTasot}</label>
  <textarea
    value={workLevel}
    onChange={(e) => {
      setWorkLevel(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setWorkLevel, workLevel)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{tyoskentelynPainoarvo}</label>
  <textarea
    value={workFocus}
    onChange={(e) => {
      setWorkFocus(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setWorkFocus, workFocus)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group" style={{ marginTop: "5vh" }}>
  <label>8. TOIMINTAKULTTUURI JA YRITYKSEN ARVOT</label>
</div>

<div className="form-group">
  <label>{tyoryhma}</label>
  <textarea
    value={teamDescription}
    onChange={(e) => {
      setTeamDescription(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setTeamDescription, teamDescription)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{toimintakulttuuri}</label>
  <textarea
    value={cultureTraits}
    onChange={(e) => {
      setCultureTraits(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setCultureTraits, cultureTraits)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{johtamiskaytannot}</label>
  <textarea
    value={leadershipStyle}
    onChange={(e) => {
      setLeadershipStyle(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setLeadershipStyle, leadershipStyle)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{arvot}</label>
  <textarea
    value={companyValues}
    onChange={(e) => {
      setCompanyValues(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setCompanyValues, companyValues)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>9. HOUKUTTELUTEKIJÄT</label>
  <label>{motivaatiotekijat}</label>
</div>

<div className="form-group">
  <label>{organisaatio}</label>
  <textarea
    value={organizationAppeal}
    onChange={(e) => {
      setOrganizationAppeal(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setOrganizationAppeal, organizationAppeal)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{tyo}</label>
  <textarea
    value={jobAppeal}
    onChange={(e) => {
      setJobAppeal(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setJobAppeal, jobAppeal)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{kuukausiansiot}</label>
  <textarea
    value={salaryAndIncentives}
    onChange={(e) => {
      setSalaryAndIncentives(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setSalaryAndIncentives, salaryAndIncentives)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{ihmiset}</label>
  <textarea
    value={peopleAppeal}
    onChange={(e) => {
      setPeopleAppeal(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setPeopleAppeal, peopleAppeal)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{mahdollisuudet}</label>
  <textarea
    value={growthOpportunities}
    onChange={(e) => {
      setGrowthOpportunities(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setGrowthOpportunities, growthOpportunities)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{sisaisetHakijat}</label>
  <textarea
    value={internalCandidates}
    onChange={(e) => {
      setInternalCandidates(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setInternalCandidates, internalCandidates)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{ulkopuolisetHakijat}</label>
  <textarea
    value={externalCandidates}
    onChange={(e) => {
      setExternalCandidates(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setExternalCandidates, externalCandidates)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{kilpailijat}</label>
  <textarea
    value={competitors}
    onChange={(e) => {
      setCompetitors(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setCompetitors, competitors)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{kielletytTahot}</label>
  <textarea
    value={prohibitedContacts}
    onChange={(e) => {
      setProhibitedContacts(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setProhibitedContacts, prohibitedContacts)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{finaalihaastattelijat}</label>
  <textarea
    value={finalInterviewParticipants}
    onChange={(e) => {
      setFinalInterviewParticipants(e.target.value);
      e.target.style.height = "auto";
      e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
    }}
    onKeyDown={(e) => handleKeyDown(e, setFinalInterviewParticipants, finalInterviewParticipants)}
    className="form-control"
    style={{
      overflow: 'hidden',
      height: '1vh', // Alkuperäinen korkeus
      marginBottom: "1vh"
    }}
  />
</div>

<div className="form-group">
  <label>{alustavaFinaalihaastatteluPäivä}</label>
  <input
    type="date"
    value={finalInterviewDate}
    onChange={(e) => setFinalInterviewDate(e.target.value)}
  />
</div>

<div className="form-group">
  <label>{top5Kriteerit}</label>
  <textarea
    value={top5Criteria}
    onChange={(e) => {setTop5Criteria(e.target.value); 
    e.target.style.height = "auto";
    e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '1vh';
  }}
    onKeyDown={(e) => handleKeyDown(e, setTop5Criteria, top5Criteria)}
    style={{
      overflow: 'hidden',
      height: '1vh', 
      marginBottom: "1vh"
    }}
  />
</div>
<div style={{ display: 'flex', justifyContent: 'center' }}>
    <Button
          className="SignIn"
          variant="primary"
          type="button"
          style={{ flexGrow: 1, maxWidth: "200px", marginLeft: "1%" }}
          onClick={openModal} // Avaa vahvistusmodaali
        >
          Lähetä
        </Button>
</div>
</div>
 )}
  {/* Toinen lomake */}
  {activeTab === 'form2' && (
        <div><p style={{ position: 'absolute', top: '2vh', right: '5vh' }}>
        Lue tietosuojailmoitus <a href="https://www.haastattelusimulaattori.com/policy2.html" target="_blank" rel="noopener noreferrer">täältä</a> 
          </p>
          <h2>AVOIN HAKUPROFIILI</h2>
          <div className="form-group">
          <div className="form-group">
        <label>{yritys}</label>
        <input
          type="text"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>{yhteyshenkilo}</label>
        <input
          type="text"
          value={contactPerson}
          onChange={(e) => setContactPerson(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>Valitse konsultti:</label>
        <select value={valittuKonsultti} onChange={(e) => setValittuKonsultti(e.target.value)}>
          <option value="">{latausKaynnissa ? "Loading..." : "Valitse konsultti"}</option>
          {!latausKaynnissa && konsultit.map((konsultti, index) => (
            <option key={index} value={konsultti}>{konsultti}</option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Valitse haastattelukieli:</label>
        <select value={valittuKieli} onChange={(e) => setValittuKieli(e.target.value)}>
          <option value="">Valitse haastattelukieli</option>
          { kielet.map((konsultti, index) => (
            <option key={index} value={konsultti}>{konsultti}</option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>{haettavaTehtava}</label>
        <input
          type="text"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label>{toimiala}</label>
        <input
          type="text"
          value={industry}
          onChange={(e) => setIndustry(e.target.value)}
        />
      </div>
            <label>Työnkuvaus</label>
            <textarea
              
              value={avoin}
              placeholder='Kopio työnkuvaus tähän'
              onChange={(e) => {
                setAvoin(e.target.value);
                e.target.style.height = "auto"; // Poistaa edellisen korkeusasetuksen
                e.target.style.height = e.target.value.trim() !== "" ? `${e.target.scrollHeight}px` : '10vh'; // Asettaa korkeuden syötteen mukaan
              }}
              style={{
                overflow: 'hidden',
                height: '10vh', // Aluksi kenttä on isompi
                marginBottom: "1vh",
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
    <Button
          className="SignIn"
          variant="primary"
          type="button"
          style={{ flexGrow: 1, maxWidth: "200px", marginLeft: "1%" }}
          onClick={openModal} // Avaa vahvistusmodaali
        >
          Lähetä
        </Button>
</div>
        </div>
      )}
{/* Vahvistusmodaali */}
<Modal
  isOpen={isModalOpen}
  onRequestClose={closeModal}
  contentLabel="Vahvistus"
  style={{
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30vw',
      height: '60vh',
      maxWidth: '90vw',
      maxHeight: '80vh',
      padding: '20px',
      textAlign: "center",
    }
  }}
>
  {isSending && !ready ? (
    <div style={{ textAlign: "center" }}>
      <img className="logopikku" src={Logopikku} alt="kuva" />
      <div>
        <p>Loading...just the moment</p>
      </div>
    </div>
  ) : ready ? (
    <div style={{ textAlign: "center" }}>
      <h2>Lähetys valmis!</h2>
      <p>Tietojen lähetys onnistui.</p>
      <Button variant="primary" style={{ marginTop: "2vh" }} onClick={() => {
        closeModal();
        window.location.reload();
      }}>Sulje</Button>
    </div>
  ) : (
    // Tarkista, onko virheitä ja näytä ne modalissa
    Object.keys(formErrors).length > 0 ? (
      <>
        <h2>Huom!</h2>
        <ul style={{ color: "red", listStyle: "none", padding: 0 }}>
          {Object.entries(formErrors).map(([field, error]) => (
            <li key={field}>{error}</li>
          ))}
        </ul>
        <Button variant="danger" onClick={closeModal}>Sulje</Button>
      </>
    ) : (
      <>
        <h2>Vahvistus</h2>
        <p>Haluatko varmasti lähettää tiedot?</p>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '5vh', marginBottom: '5vh' }}>
          <Button variant="success" onClick={confirmSubmit}>Lähetä</Button>
          <Button variant="danger" onClick={closeModal}>Peruuta</Button>
        </div>
      </>
    )
  )}
</Modal>

    </div>
  );
}

export default Mandaatti;
/*
<div className="form-group">
        <label>{alustavaFinaalihaastatteluPäivä}</label>
        <input
          type="date"
          value={finalInterviewDate}
          onChange={(e) => setFinalInterviewDate(e.target.value)}
        />
      </div>


*/