import { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import './App.css';
import { signInWithGoogle, handleGoogleSignInRedirect } from './googleSignIn';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Logopikku from "./logopikku.png";
import Logo from "./logo.png";
import Pro from "./pro.png";
import ConAdd from "./ConAdd";
import Volume from "./volume2";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Simulator from "./haastattelusimu";


function AuthenticationForm2({ onAddLogin, onAddHaastattelu }) {
    const [isLoginForm, setIsLoginForm] = useState(false);
    const [email, setEmail] = useState(""); //Käyttäjän syöttämä ID
    const [name, setName] = useState(""); //Mikä yritys, prosource
    const [pageNumber, setpageNumber] = useState(1);
    const [kysymykset, setKysymykset] = useState([]); //Back palauttaa kyssärit tähän.
    const [haastattelu, setHaastettelu] = useState(false);
    const [vastaukset, setVastaukset] = useState([]); //Hakijan vastaukset
    const [position, setPosition] = useState(""); //Back palauttaa kyssärit tähän.
    const [owner, setOwner] = useState(""); //konsultti
    const [mandaatti, setMandaatti] = useState(""); //konsultti
    const [kieli, setKieli] = useState(""); //konsultti
    const [isAccepted, setIsAccepted] = useState(false);

    //napit

    const [oikealle, setOikealle] = useState(""); 
    const [vasemmalle, setVasemmalle] = useState(""); 
    const [valmisNappi, setValmisNappi] = useState(""); 
    
    const oikealleFin = "Seuraava";
    const vasemmalleFin = "Edellinen";
    const valmisNappiFin = "Aloita haastattelu!";

    const oikealleEng = "Next";
    const vasemmalleEng = "Previous";
    const valmisNappiEng = "Start the interview!";

    const oikealleSwe = "Nästa";
    const vasemmalleSwe = "Föregående";
    const valmisNappiSwe = "Börja intervjun!";



    const {
      transcript,
      resetTranscript,
      browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const [ohjeet, setOhjeet] =useState ([
     
    ]);

    const ohjeetFin =[
      "<h2>Tervetuloa haastatteluun</h2><p>Tämä haastattelu on virtuaalinen, ja vastauksesi lähetetään automaattisesti henkilölle, joka on vastuussa rekrytointiprosessista.<br/> <br/>Käydään seuraavaksi ohjeita lävitse. <br/><br/>Voit selata ohjeita alapuolisilla napeilla.</p>",
      `<h2>Haastattelu simuloi normaalia haastatteluprosessia.</h2><p>Haastattelu alkaa 20 sekunnin laskurilla, jonka jälkeen sinulla on 90 sekuntia aikaa vastata kysymykseen, jonka jälkeen seuraa jälleen 20 sekunnin tauko.<br/><br/>Tauon jälkeen seuraava kysymys ilmestyy automaattisesti ja 90 sekunnin ajastin käynnistyy. Haastatteluprosessia ei voi keskeyttää.</p>`,
      "<h2>Mikrofonin salliminen</h2><p>Simulaattori tarvitsee mikrofonin käyttöoikeuden tallentaakseen vastauksesi<br/><br/>Tarkista alhaalla olevasta palkista, että mikrofonin äänenvoimakkuus yltää keskitasolle.</p>",
      `<h2>Kysymyksiä on yhteensä ${kysymykset.length} kpl</h2><p>Kun olet vastannut viimeiseen kysymykseen, simulaattori vahvistaa, että haastattelu on päättynyt ja vastaukset on lähetetty rekrytointivastuulliselle, jonka jälkeen voit sulkea sivun.</p>`,
      "<h2>Yksityisyyden suoja</h2><p>Lue tarkemmat yksityisyyttä koskevat tiedot ja ohjeet alla olevasta linkistä <br/> <br/><a href='https://www.haastattelusimulaattori.com/policy3.html' target='_blank'>Privacy Policy</a></p>",
      "<h2>Muista!</h2><p>Kerran aloitettuasi, haastatteluprosessia ei voi keskeyttää.<br/><br/><br/>Onnea haastatteluun! Olkoon voima kanssasi.</p>"
    
    ];
    const ohjeetEng = [
      "<h2>Welcome to the interview</h2><p>This interview is virtual, and your answers will be automatically sent to the person responsible for the recruitment process.<br/> <br/>Let's go through the instructions next. <br/><br/>You can navigate the instructions using the buttons below.</p>",
      `<h2>The interview simulates a normal interview process.</h2><p>The interview begins with a 20-second countdown, after which you have 90 seconds to answer the question, followed by another 20-second break.<br/><br/>After the break, the next question will automatically appear, and the 90-second timer will start. The interview process cannot be paused.</p>`,
      "<h2>Allow microphone access</h2><p>The simulator requires microphone access to record your answers.<br/><br/>Check the bar at the bottom that the microphone volume reaches a medium level.</p>",
      `<h2>There are a total of ${kysymykset.length} questions</h2><p>Once you have answered the last question, the simulator will confirm that the interview is over and the answers have been sent to the recruitment officer. After that, you can close the page.</p>`,
      "<h2>Privacy Protection</h2><p>Read more detailed privacy information and instructions via the link below.<br/> <br/><a href='https://www.haastattelusimulaattori.com/policy3.html' target='_blank'>Privacy Policy</a></p>",
      "<h2>Remember!</h2><p>Once you start, the interview process cannot be paused.<br/><br/><br/>Good luck with the interview! May the force be with you.</p>"
    ];

    const ohjeetSwe = [
      "<h2>Välkommen till intervjun</h2><p>Den här intervjun är virtuell, och dina svar skickas automatiskt till personen som är ansvarig för rekryteringsprocessen.<br/> <br/>Låt oss gå igenom instruktionerna nu. <br/><br/>Du kan navigera genom instruktionerna med hjälp av knapparna nedan.</p>",
      `<h2>Intervjun simulerar en normal intervjuprocess.</h2><p>Intervjun börjar med en 20 sekunders nedräkning, därefter har du 90 sekunder på dig att svara på frågan, följt av ytterligare en 20 sekunders paus.<br/><br/>Efter pausen visas nästa fråga automatiskt och 90-sekunders timern startar. Intervjuprocessen kan inte avbrytas.</p>`,
      "<h2>Tillåt mikrofonåtkomst</h2><p>Simulatorn behöver mikrofonåtkomst för att spela in dina svar.<br/><br/>Kontrollera fältet under att mikrofonvolymen når över medelnivå.</p>",
      `<h2>Det finns totalt ${kysymykset.length} frågor</h2><p>När du har svarat på den sista frågan kommer simulatorn att bekräfta att intervjun är avslutad och att svaren har skickats till den rekryteringsansvarige. Därefter kan du stänga sidan.</p>`,
      "<h2>Skydd av integritet</h2><p>Läs mer detaljerad information om integritet och instruktioner via länken nedan.<br/> <br/><a href='https://www.haastattelusimulaattori.com/policy3.html' target='_blank'>Privacy Policy</a></p>",
      "<h2>Kom ihåg!</h2><p>När du väl har börjat kan intervjuprocessen inte pausas.<br/><br/><br/>Lycka till med intervjun! Må kraften vara med dig.</p>"
    ];
    
  
    // Funktiot sivunumeroiden muuttamiseksi
    const seuraavaSivu = () => {
      if (pageNumber < ohjeet.length) {
        setpageNumber(pageNumber + 1);
      }
    };
  
    const edellinenSivu = () => {
      if (pageNumber > 1) {
        setpageNumber(pageNumber - 1);
      }
    };

    //Käynnistetään haastattelu. Ota mallia itse simusta.
    const aloitaHaastattelu = () => {
      setHaastettelu(true);
      
    };

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [forgot, setForgot] = useState(false);
    const [finland, setFinland] = useState(false);
    const [logIn, setLogIn] = useState(false);
    const [newpass, setNewpass] = useState(false);
    const [titles, setTitles] = useState({signin: "Sign in",
    signup: "Sign up",
    forgot: "Forgot your password?",
    email: "Email",
    password:"Password",
    update: "Password update",
    placeemail: "Your email",
    yourpass: "Your password",
    createpass: "Create password",
    submit: "submit",
    new: "New password",
    loading: "Loading..."})

    const titlesFi = {
      signin: "Sign in",
        signup: "Rekisteröidy",
        forgot: "Unohditko salasanasi?",
        email: "Sähköposti",
        password:"Salasana",
        update: "Salasanan uusiminen",
        placeemail: "Sinun sähköpostiosoite",
        yourpass: "Sinun salasanasi",
        createpass: "Luo uusi salasana",
        submit: "Lähetä",
        new: "Uusi salasana",
        loading: "Loading...",
        id : "Käyttäjätunnus",
        id2 : "Kirjaudu sisään käyttäjätunnuksella",
        code : "Invitation code",
        code2 : "Enter your personal invitation code."
      }

      const titlesEng = {
        signin: "Sign in",
        signup: "Sign up",
        forgot: "Forgot your password?",
        email: "Email",
        password:"Password",
        update: "Password update",
        placeemail: "Your email",
        yourpass: "Your password",
        createpass: "Create a new password",
        submit: "submit",
        new: "New password",
        loading: "Loading...",
        id : "User ID",
        id2 : "Use your user ID to log in",
        code : "Invitation code",
        code2 : "Enter your personal invitation code."
        }

      

    const [info, setInfo] = useState('');
    const [info2, setInfo2] = useState('');

   
    useEffect(() => {
      // Hanki nykyinen URL
      if (name == ""){
  
  
      const currentUrl = window.location.href;
  
      // Luo URL-objekti ja jaa polku
      const parsedUrl = new URL(currentUrl);
      const pathParts = parsedUrl.pathname.split('/');
  
      // Ota haluttu osa (tässä tapauksessa prosource)
      if (pathParts.length > 1) {
        setName(pathParts[1]); // tallennetaan "prosource" tilaan
      }
      }
   
    }, [name]);

    useEffect(() => {
      
      if(haastattelu){
        onAddHaastattelu(true);
      
      }
      
    }, [haastattelu]);

    useEffect(() => {

      
      if(kieli != ""){
        if (kieli == "Suomi"){
          setOhjeet(ohjeetFin);
          setOikealle(oikealleFin);
          setVasemmalle(vasemmalleFin);
          setValmisNappi(valmisNappiFin);
        }else if (kieli == "Ruotsi"){
   
          setOhjeet(ohjeetSwe);
          setOikealle(oikealleSwe);
          setVasemmalle(vasemmalleSwe);
          setValmisNappi(valmisNappiSwe);

        }else if (kieli == "Englanti"){

          setOhjeet(ohjeetEng);
          setOikealle(oikealleEng);
          setVasemmalle(vasemmalleEng);
          setValmisNappi(valmisNappiEng);
        }
      
      
      }
    
    }, [kieli]);


    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleSend(e);
      }
      else if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation(); 
        // Voit myös tehdä muita toimintoja tässä, jos haluat
      }
    };
    const handleKeyDown2 = (e) => {
      if (e.key === 'Enter') {
        handleSend(e);
      }
      else if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation(); 
        // Voit myös tehdä muita toimintoja tässä, jos haluat
      }
    };
    
    const handleSend = async (event) => {

      event.preventDefault();
      
      setIsLoading(true);
        //fetch('http://localhost:5001/simudatauser/us-central1/api/hakija', {
          fetch('https://us-central1-simudatauser.cloudfunctions.net/api/hakija', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email, group: name})
      })

      .then(response => response.json())
      .then(data => { 
    
          setIsLoading(false);
          
          if (data.status == "ok" ){

      
            const kysymyksetTaulukkona = JSON.parse(data.final); // Muuntaa merkkijonon taulukoksi
            setKysymykset(kysymyksetTaulukkona); // Tallentaa taulukon tilaan
            setOwner(data.owner);
            setPosition(data.position);
            setLogIn(true);
    
            setMandaatti(data.mandaatti);
            setKieli(data.kieli);
           
          }
          else if (data.status == "vastannut jo" ){
            if(!finland){
      
              setInfo("You have already answered the questions, you cannot answer again");
              setInfo2("You have already answered the questions, you cannot answer again");
            }else{          
      
              setInfo("You have already answered the questions, you cannot answer again");
              setInfo2("You have already answered the questions, you cannot answer again");             
            }

          }

          else if (data.status == "ei ok"){
            if(!finland){
      
              setInfo("The invitation code is not valid");
              setInfo2("The invitation code is not valid");
            }else{          
  
              setInfo("The invitation code is not valid");
              setInfo2("The invitation code is not valid");
              
            }
          }
          else {
            if(!finland){
            setInfo("Something went wrong, please contact Lassi.");
            setInfo2("Something went wrong, please contact Lassi.");
            }else {
              setInfo("Jotain meni pieleen, ole hyvä ja ota yhteyttä Lassiin.");
              setInfo2("Jotain meni pieleen, ole hyvä ja ota yhteyttä Lassiin.");
            }
          }
        }
      ) 
      .catch(error => console.error(error));  

     setInfo("");
    setInfo2("");
      
      //setInfo2("Coming soon!");
    };


      //Google signin if needed
      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
            setEmail(user.email);
          } else {
            setEmail("");
          }
        });

        const domain = window.location.hostname;

        if (domain === 'theinterviewsimulator.com' || domain === 'www.theinterviewsimulator.com') {
          setFinland(false);
          setTitles(titlesEng);
        } else {
          setFinland(true);
          setTitles(titlesFi);
        }
      
        // Cleanup function, which removes the user state listener.
        return () => unsubscribe();
      }, []);
    
      
      useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            event.preventDefault();
          }
        };
        const handleKeyDown2 = (event) => {
          if (event.key === 'Escape') {
            event.preventDefault();
          }
        };
    
    
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keydown', handleKeyDown2);
        
    
        // Komponentin poistuessa, poista tapahtumankäsittelijä
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
          window.removeEventListener('keydown', handleKeyDown2);
        };
      }, []);

      const handleClosee = () => {
        setInfo2(""); // Nollaa info2:n arvo
      };

    //Haastatteluosi-----------------------------------------------------------------------------------------------------------------------------




  
    if (!isLoading && !forgot && info2 == "" && !logIn && !haastattelu){
      return (
        <div >
          <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding:"10px",
            
          }}
        >
          <img className="FormLogo" src={Pro} style={{height:"7vh", marginTop:"2vh",marginBottom:"2vh"}}></img>
          <h1  className="FormTitle" style={{fontSize:"1.95em", marginBottom:"4vh"}}>The Interview Simulator</h1>
          <h3 style={{fontSize:"1.3em"}}>{isLoginForm ? titles.signin : titles.signin}</h3>
          <Row
            className="justify-content-md-center"
            style={{
              width: "100%",
              height: "55vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Col >
              <Card className='Card' style={{ width: "80%", height: "30vh", marginLeft:"10%" }}>
                <Card.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    borderRadius:"0"
                  }}
                >
                  <Form >
                   
                  <Form.Group>
                    <Form.Label htmlFor="email">{titles.code}</Form.Label>
                    <Form.Control
                      id="email"
                      type="email"
                      placeholder={titles.code2}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    
                  </Form.Group>
                    
                  </Form>
                  <Button
                      className='SignIn'
                      variant="primary"
                      type="submit"
                      
                      style={{marginTop:"0vh", width:"40%", marginRight:"auto", marginLeft:"auto"}}
                      onClick={(e)=> handleSend(e)}
                      >
                      {isLoginForm ? titles.signin : titles.signin}
                    
                  </Button>
                </Card.Body>
              </Card>
              
              <div className='formInfo' style={{marginLeft:"10%"}}>{info}</div>
            </Col>
            <p className="support">support@lassialbin.com</p>
          </Row>
         
        </Container>
        </div>
      )
    }
    else if (logIn && !haastattelu){ //Tähän nyt itse sisältö...Eli eka varmaan jokin ohje

      return (
        <Container>
          <div style={{
            display: 'flex',
            
           
            minHeight: '80vh',          // Varmistaa, että alue on riittävän korkea
            textAlign: 'center',        // Keskittää tekstit
            flexDirection: 'column',    // Asettaa elementit pystysuunnassa
            padding: '20px',  
            marginTop:"1vh"          // Lisää hieman tilaa ympärille
          }}>
            {/* Renderöidään HTML-sisältö */}
            <div 
                dangerouslySetInnerHTML={{ __html: ohjeet[pageNumber - 1] }} 
                style={{
                  fontSize: '1.2rem',       // Asetetaan tekstin koko
                  lineHeight: '1.4',        // Riviväli, joka tekee tekstistä selkeämmän
                  wordBreak: 'break-word',  // Varmistaa, että pitkät sanat katkeavat tarpeen mukaan
                }}
              />
              {pageNumber === 3 && (
                <Col >
                  <Volume/>
                </Col>
              )}

          {pageNumber === 5 && (
              <div style={{ marginTop: '10px' }}>
                <input
                  type="checkbox"
                  id="acceptPrivacy"
                  checked={isAccepted}
                  onChange={() => setIsAccepted(!isAccepted)}
                />
                <label htmlFor="acceptPrivacy" style={{ marginLeft: '5px' }}>
                  Olen lukenut ja hyväksynyt ehdot.
                </label>
              </div>
            )}    
            

          </div>
  
          {/* Tyhjä alue, joka estää nappien nousemisen liian ylös */}
          <div style={{ minHeight: '80vh' }} />
          {/* Jos kysymykset-taulukossa on dataa, renderöidään lista */}
          
            
      
          <div
            style={{
              position: 'fixed',
              bottom: '0',
              left: '0',
              right: '0',
              padding: '10px',
              backgroundColor: 'white',
              boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
              zIndex: 1000,
            }}
          >
            <Row>
              <Col className="text-start">
                <Button
                  variant="primary"
                  onClick={edellinenSivu}
                  disabled={pageNumber === 1}
                >
                  {vasemmalle}
                </Button>
              </Col>

              {pageNumber === 6 && (
                <Col className="text-center">
                  <Button
                    variant="success"
                    onClick={aloitaHaastattelu}
                    style={{
                      whiteSpace: 'nowrap',
                      width: 'auto',
                    }}
                  >
                    {valmisNappi}
                  </Button>
                </Col>
              )}

              <Col className="text-end">
                <Button
                  variant="primary"
                  onClick={seuraavaSivu}
                  disabled={pageNumber === ohjeet.length || (pageNumber === 5 && !isAccepted)}
                >
                  {oikealle}
                </Button>
              </Col>
            </Row>

            {/* Valintaruutu ja Privacy Policy linkki näkyy vain, jos ollaan viimeisellä sivulla */}
            
          </div>
                      
                           
        </Container>
      );
    }
    //Tämä silloin kun haastattelu on käynnissä
    else if (logIn && haastattelu){ //Tähän nyt itse sisältö...Eli eka varmaan jokin ohje

      return (
        <div>
            <Simulator kre={kysymykset.length} lan={kieli} que={kysymykset} haa={haastattelu} ema={email} own={owner} man={mandaatti}/>   
        </div>
      );
    }
    else {
      return (

        <div style={{ textAlign: "center" }}>
          <img className="logopikku" src={Logopikku} alt="kuva" />
          {info2 == "" && (
            <div>
          <p>{titles.loading}</p>
          </div>
          )}
          {info2 !== "" && (
            <div>
              <p>{info2}</p>
              
              <Button
                    className='SignIn'
                    variant="primary"
                    type="submit"
                    style={{marginTop:"0vh", width:"40%", marginRight:"auto", marginLeft:"auto"}}
                    onClick={(e)=> handleClosee(e)}
                    >
                    {finland ? <p>Sulje</p> : <p>Close</p>}
                  
                </Button>
            </div>
          )}
        </div>
      );
    }
    ;}

export default AuthenticationForm2;


//`<h2>Haastattelu simuloi normaalia haastatteluprosessia.</h2><p>Haastattelu alkaa 20 sekunnin laskurilla, jonka jälkeen sinulla on 90 sekuntia aikaa vastata kysymykseen, jonka jälkeen seuraa jälleen 20 sekunnin tauko.<br/><br/>Tauon jälkeen seuraava kysymys ilmestyy automaattisesti ja 90 sekunnin ajastin käynnistyy. Haastatteluprosessia ei voi keskeyttää.</p>`,