
import './App.css';
import { useEffect, useRef, useState } from 'react'
import Main from "./Main";
import Fit from "./Fit";
import Mot from "./Mot";
import Ind from "./Ind";
import Volume from "./Volume";
import FeedbackModal from "./feedback";
import Modal from 'react-modal';
import Finitto from "./kreditsfinitto";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import ResponseCounter from "./counter"
import Logopikku from "./logopikku.png";
import { AiOutlineCopy } from 'react-icons/ai';
import { VscFeedback} from 'react-icons/vsc';
import Nav from "./navbar";
import { AiOutlineAudio,AiOutlineAudioMuted } from "react-icons/ai";
import { isMobile, isTablet } from 'react-device-detect';

function Simulator(props) {
 
   const {
    transcript,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const [supportState, setSupportState] = useState(true);
  const letGoogleApi = useRef(false);
  
  useEffect(() => {
    if (!browserSupportsSpeechRecognition || isMobile || isTablet) {
      setSupportState(false);
      
    }
  }, [browserSupportsSpeechRecognition]);
  
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const startTalking = useRef(false);
  const [startCounter, setStartCounter] = useState(["3","2","1","Start Talking"]);
  const [firstCount, setFirstCount] = useState(false);
  const [secondCount, setSecondCount] = useState(false);
  const [countdown, setCountdown] = useState(90);
  const [timesUp, setTimesUp]= useState(false);
  const question = useRef("");
  const [count, setCount] = useState(0);
  const [analyzing, setAnalyzing] = useState(false);
  const [chatgpt, setChatgpt] = useState("");
  const [buttonText, setButtonText] = useState("Ready");  
  const [tryAgain, setTryAgain] = useState(false);
  const [trya, setTrya] = useState("Try again?");  
  const [kreditifinitto, setKredititFinitto] = useState(false);

  const abortControllerRef = useRef(null);
  

  //Äänijuttuja-------------------------------------------------------------------
  const [recording, setRecording] = useState(false);
  const mediaRecorder = useRef(null);
  let recordedChunks = [];

  const requestMicrophonePermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
  
      if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
        return true;
      } else {
        console.error('Mikrofonin käyttölupa evätty');
        return false;
      }
    } catch (err) {
      console.error('Virhe mikrofonin käyttöluvan pyytämisessä:', err);
      return false;
    }
  };

  const showPermissionInstructions = () => {
    handleCloseModal();
    alert('Microphone access denied. Open your browser settings and grant permission for microphone use on this site.');

  };
 
 const startRecording = async () => {
  
  const permissionGranted = await requestMicrophonePermission();

  if (!permissionGranted) {
    showPermissionInstructions();
    return;
  }

  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  mediaRecorder.current = new MediaRecorder(stream);

  mediaRecorder.current.ondataavailable = (event) => {
    if (event.data.size > 0) {
      recordedChunks.push(event.data);
    }
  };

  mediaRecorder.current.onstop = () => {
    const blob = new Blob(recordedChunks, { type: 'audio/webm;codecs=opus' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = async () => {

      const base64data = reader.result.split(',')[1];
      const audioUrl = URL.createObjectURL(blob);
      const audio = new Audio(audioUrl);
      
      const AudioContextConstructor = window.AudioContext || window['webkitAudioContext'];
      const audioContext = new AudioContextConstructor();
      const sampleRate = audio.mozSampleRate || audio.webkitSampleRate || audioContext.sampleRate;
     
      if (letGoogleApi.current){   
        sendAudioToCloudFunction(base64data, sampleRate); // Pass the sample rate to the back-end
      }
       
    };
  };

  mediaRecorder.current.start(10); // Set a timeslice for data collection
  setRecording(true);
};

const stopRecording = async () => {
  if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
    mediaRecorder.current.stop();
    setRecording(false);
  }
};

const sendAudioToCloudFunction = (audio, rate) => {

  fetch('https://us-central1-simudatauser.cloudfunctions.net/api/speech-to-text', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ audio:audio, lan:props.lan, sampleRate:rate}),
  })

  .then(response => response.text())
  .then(data => {
    handleSend(data);
    //console.log(data);
   
  })
  .catch(error => (console.error('Error:', error), 
            setChatgpt("Oops! It seems like there's an issue with our artificial intelligence. We'll fix the problem promptly. Please try again later."),
            gptIsReady(),
            setAnalyzing(false),
            speech.current=""));
  
};

  const [options, setOptions] = useState(props.selectedoptions);
 
  const [loadingText, setLoadingText] = useState("Just a sec, I´am building the feedback");
  const speech = useRef("");
  const [kredits, setKredits] = useState(props.kre);
  const chatIsReady = useRef(false);

  const [feedback, setFeedback] = useState({ rating: 5, comments: '' });

  const isMicrophoneMuted = useRef();

  const handleRatingChange = (e) => {
    setFeedback({ ...feedback, rating: e.target.value });
  };

  const handleCommentsChange = (e) => {
    setFeedback({ ...feedback, comments: e.target.value });
  };

  const handleSubmit = (e) => {
    setFeedback({});
    e.preventDefault();
    const email = sessionStorage.getItem('email');
    const feed = `${feedback.rating} - ${feedback.comments}`;
    const handleSend = async () => {
      fetch('https://us-central1-simudatauser.cloudfunctions.net/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ feedback: feed, email:email })
      })
        .then(response => response.text())
        .then(data => {
          
        })
        .catch(error => console.error(error));
      handleCloseModal2();
    };
    handleSend();
  }
 
  const handleCloseModal2 = () => {
    setIsOpen2(false);
  }
  const isopen2= () => {
    setIsOpen2(true);
  }

  const kreditfinittofalse= () => {
    setKredititFinitto(false)
  }

  const handleCloseModal = () => {
    startTalking.current = false;
    setIsOpen(false);
    setIsOpen2(false);
    if (!supportState){
      letGoogleApi.current = false;
      stopRecording();
      
    }else{
      SpeechRecognition.stopListening();
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    setTimesUp(false);
    setCountdown(90);
    setCount(0);
    setAnalyzing(false);
    setTryAgain(false);
    setCopied(false);

    if (kredits == 0){
      setKredititFinitto(true);
    }
  }

  const timeIsUp = () => {
    
    setSecondCount(false);
    setCountdown(90);
    startTalking.current = false;
    setAnalyzing(true); 
    if (supportState){
      handleSend();
    }else{
      stopRecording();
    }
  }
  const [speechLanguage, setSpeechLanguage] = useState(props.lan);
  
  function getAnswer(q) {
    
    if (kredits >= 1){
      chatIsReady.current = false;
      setSecondCount(false);
      setIsOpen(true);
      setCount(0);
      if (supportState){
        resetTranscript();
        speech.current="";
        SpeechRecognition.startListening({ continuous: true, language: speechLanguage }); 
      }else {
        letGoogleApi.current = true;
      }
      
      question.current = q;
      setChatgpt("");  
    }else{
     //Tähän functiokutsu joka hakee countit, ja palauttaa ne
     //loadCredits(q);
      setKredititFinitto(true);
    }
    //changeCredits("red");
  }

  function sameQuestion() {
    if (kredits >= 1){
      setCopied(false);
      chatIsReady.current = false;
      setTimesUp(false);
      setSecondCount(false)
      setIsOpen(true);
      setCount(0);
      
      if (supportState){
        resetTranscript();
        speech.current="";
        SpeechRecognition.startListening({ continuous: true, language: speechLanguage }); 
      }else{
        letGoogleApi.current = true;
      }
      setChatgpt("");  
      setTryAgain(false);
    }else{
     //Tähän vois laittaa kredittien latauksen joskus...
     
      setKredititFinitto(true);
    }

  }

  const [copied, setCopied] = useState(false)
  const handleCopy = () => {
    navigator.clipboard.writeText(chatgpt);
    setCopied(true);
  };

  const gptIsReady = () => {  
    chatIsReady.current = true;
  }

  const changeAnalyzing = () => {
    setAnalyzing(false);
    chatIsReady.current = false;
    setTryAgain(true);
  }

  function deleteCookie() {
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('credits');
    window.location.reload();
  }
  function homepage(){
    window.location.reload();
  }

  useEffect(() => {
    let intervalId;
    if (count < 4 && isOpen === true && startTalking.current === false ){  
      setTimesUp(false);
      setFirstCount(true);
      intervalId = setInterval(() => {
        setCount(count => count + 1);   
      }, 1000);
    }else if (count === 4){
      startTalking.current = true;  
      setFirstCount(false); 
      setSecondCount(true);
      if (!supportState){
        startRecording();
      } 
    }
    
    return () => clearInterval(intervalId); 
  }, [isOpen, count]); 

  useEffect(() => {
    if (startTalking.current){
      speech.current = transcript;
    }
   }, [transcript]); 


   useEffect(() => {
    let intervalId;
    if (countdown > 0 && startTalking.current === true && secondCount){
      intervalId = setInterval(() => {
        setCountdown(countdown => countdown - 1);     
      }, 1000);
    }else if (countdown === 0){   
      timeIsUp();    
    }
    if (countdown === 10){
      setTimesUp(true);
    }
    
    return () => clearInterval(intervalId);
  }, [countdown,startTalking.current]); 

  useEffect(() => {

    if (props.lan === "fi"){
      setStartCounter(["3","2","1","Aloita puhuminen"]);
      setButtonText("Olen valmis");
      setLoadingText("Hetki pieni, kirjoitan palautetta...");
      setTrya("Kokeile uudestaan?");
      
    }
    else if (props.lan === "en-US"){
      setStartCounter(["3","2","1","Start Talking"]);
      setButtonText("I´m ready");
      setLoadingText("Just a sec, I´m building the feedback...");
      setTrya("Try again?");
      
      
    }
    else if (props.lan === "es-ES"){
      setStartCounter(["3","2","1","Empieza a hablar"]);
      setButtonText("Estoy listo");
      setLoadingText("Solo un segundo, estoy construyendo la retroalimentación...");  //
      setTrya("¿Intentar de nuevo?");
      
    }
  
  }, [props.lan]); 
  
    const handleSend = async (text) => {
      
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      
      const email = sessionStorage.getItem('email');
        if (text && text.length < 5){
          setChatgpt("Foor some reason, I didn't hear your response. Make sure that your microphone is on, or that you have granted permissions for its use. In some cases, it might be that the browser you are using does not support the web application. You can try updating your browser or testing another one."); 
          gptIsReady();
          setAnalyzing(false);
          speech.current="";
        }else if (!text && speech.current.length < 5) {
          setChatgpt("For some reason, I didn't hear your response. Make sure that your microphone is on, or that you have granted permissions for its use. In some cases, it might be that the browser you are using does not support the web application. You can try updating your browser or testing another one."); 
          gptIsReady();
          setAnalyzing(false);
          speech.current="";
        }else {
        
        fetch('https://us-central1-simudatauser.cloudfunctions.net/api/data', {
          //fetch('http://127.0.0.1:5001/simudatauser/us-central1/api/data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email:email, question: question.current, answer: supportState ? speech.current:text , lan: props.lan, options:options, google:supportState }),
        signal: signal,
        })

        .then(response => response.text())
        .then(data => { 
          if (data == "gpt3" || data == "gpt4" ){
            setChatgpt("Oops! It seems like there's an issue with our artificial intelligence. We'll fix the problem promptly. Please try again later."); 
            gptIsReady();
            setAnalyzing(false);
            speech.current="";
          }else{
            setChatgpt(data.trim()); 
            gptIsReady();
            //setAnalyzing(false);
            speech.current="";
            changeCredits("red");
          }
            
        }) 
        .catch(error => (gptIsReady(),
        setAnalyzing(false),
        speech.current="", setChatgpt("Oops! It seems like there's an issue with our artificial intelligence. We'll fix the problem promptly. Please try again later.")));  
      }
    
    };
    
    function changeCredits(cha) {
      
      const user = sessionStorage.getItem('email');
      
      const creditsChanger = async (par, use) => {
        
        fetch('https://us-central1-simudatauser.cloudfunctions.net/api/addcount', {
          //fetch('http://127.0.0.1:5001/simudatauser/us-central1/api/addcount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ change: par, email: use})
      })

      .then(response => response.text())
      .then(data => {  
          setKredits(data);
          sessionStorage.setItem('credits', kredits.toString());
        }
      ) 
      .catch(error => console.error(error)); 
      }
      creditsChanger(cha, user);
    
    }

    /*function loadCredits(q) {
      
      const user = sessionStorage.getItem('email');
      
      const creditsLoad = async (user) => {
        
        fetch('http://localhost:5001/simudatauser/us-central1/api/getcount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: user})
      })

      .then(response => response.text())
      .then(data => {  
          setKredits(data);
          sessionStorage.setItem('credits', kredits.toString());
          getAnswer(q);
          if (data == 0){
            setKredititFinitto(true);
          }
        }
      ) 
      .catch(error => console.error(error)); 
      }
      creditsLoad(user);
    
    }*/
   
    useEffect(() => {
      sessionStorage.setItem('credits', kredits.toString());
    }, [kredits]);
    
    useEffect(() => {
      const creditsFromSessionStorage = sessionStorage.getItem('credits');
      if (creditsFromSessionStorage) {
        setKredits(Number(creditsFromSessionStorage));
      }
    }, []);

    useEffect(() => {
      let audioTrack;
      let muteHandler = function() {
  
        isMicrophoneMuted.current = true;
      };
      let unmuteHandler = function() {
      
        isMicrophoneMuted.current = false;
      };
    
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(function(stream) {
          audioTrack = stream.getAudioTracks()[0];
    
          if (audioTrack && !audioTrack.muted) {
            unmuteHandler();
          } else {
            muteHandler();
          }
    
          audioTrack.addEventListener('mute', muteHandler);
          audioTrack.addEventListener('unmute', unmuteHandler);
        })
        .catch(function(error) {
          console.log('Virhe:', error);
        });
    
      return () => {
        if (audioTrack) {
          audioTrack.removeEventListener('mute', muteHandler);
          audioTrack.removeEventListener('unmute', unmuteHandler);
        }
      };
    }, []);

  return (
    <div className="App">
      <Nav kredits={kredits} home={homepage} delete={deleteCookie} open={isopen2} className="NavBar"  />
       <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Esimerkki modaali"
        className="SimulatorModal">
        <div className='closebar'><span className='Close' onClick={handleCloseModal}>x</span></div>
        {tryAgain && 
          <div className='closebar2'>
            <span className={copied ? 'Copy2' : 'Copy'} onClick={handleCopy}><AiOutlineCopy /></span>
          </div>
        }
        <h2 className='QuestinCurrent' style={{maxWidth:"80%", marginLeft:"auto", marginRight:"auto"}}>{question.current}</h2>

        {firstCount && <div className={count < 3 ? "CountBlock" : "TalkingBlock"}><p><span className={count < 3 ? "Count" : "Talking"}>{startCounter[count]}</span></p></div>}
        {startTalking.current && <div style={{marginTop:"2em"}}><span style={{fontSize:"4em"}}>{isMicrophoneMuted.current ?<AiOutlineAudioMuted/>:<AiOutlineAudio/>}</span></div>}
        {startTalking.current && <Volume lan={props.lan} />}
        {secondCount && <div><p style={timesUp ?{ fontSize:"3em", color:"red"}:{ fontSize:"3em", color:"black", margin:"0%", marginBottom:"5vh"} }>{countdown}</p></div> }
        {startTalking.current && <button className='button1' onClick={() => timeIsUp()}>{buttonText}</button>}
        {analyzing && <div ><img className="logopikku" src={Logopikku} alt="kuva" /><div><p>{loadingText} {analyzing && <ResponseCounter support={supportState}valmis={chatIsReady} analy={changeAnalyzing} />}</p></div></div>}
        
        {!analyzing &&
        <div style={{maxWidth:"80%", width:"80%",marginLeft:"10%", textAlign:"left", marginTop:"5vh"}}>
          <div >{chatgpt.split('\n').map((item, key) => {
          return <div key={key}>{item}<br/></div>
          })}</div>
          
          {tryAgain && <div><button className='button2' onClick={()=> sameQuestion()}>{trya}</button><VscFeedback onClick={() => isopen2()} style={{ fontSize: "1.9em", marginLeft:"3vw", cursor:"pointer" }} /></div>}
        </div>}
        
      </Modal>
      <FeedbackModal
        isOpen={isOpen2}
        handleCloseModal={handleCloseModal}
        feedback={feedback}
        handleRatingChange={handleRatingChange}
        handleCommentsChange={handleCommentsChange}
        handleSubmit={handleSubmit}
        lan={props.lan}
      />

      <div className="Left">
      {kreditifinitto && <Finitto fin={kreditfinittofalse}/>}
      <Main />
      </div>
      <Ind function={getAnswer} lan={props.lan} options={options}/>
      <Fit function={getAnswer} lan={props.lan}/>
      <Mot function={getAnswer} lan={props.lan}/>      

    </div>
  );
 

}

export default Simulator;
