
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home'; // Tuodaan juuri luomamme Home-komponentti
import Hallinta from './Hallinta'; // Tuodaan myös Harrastukset-komponentti
import Mandate from './mandaatti'; // Tuodaan myös Harrastukset-komponentti
import Haastattelu from './Haastettelu'; // Tuodaan myös Harrastukset-komponentti

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />           {/* Pääsivu, itse simu */}
      <Route path="/prosource" element={<Hallinta />} /> {/* Hallinta-sivu */}
      <Route path="/prosource/mandate" element={<Mandate />} /> {/* Hallinta-sivu */}
      <Route path="/prosource/interview" element={<Haastattelu />} /> {/* Hallinta-sivu */}
    </Routes>
  );
}

export default App;